






















import { Vue, Component, Emit, Prop, Watch } from "vue-property-decorator";

@Component
export default class CartItem extends Vue {

    @Prop() private item: any
    private product: any = null
    private option: any = null

    async created() {

        (window as any).BigCartel.Product.find(this.item.product, this.setProduct)
    }

    setProduct(product: any) {
        this.product = product
        console.log('product: ', product)
    }

    @Emit()
    decreaseItem() {
        return { option: this.item.id, quantity: this.item.quantity - 1}
    }

    @Emit()
    increaseItem() {
        return { option: this.item.id, quantity: this.item.quantity + 1}
    }

    @Watch('product')
    getOption() {
        if(!this.product){
            return false
        }

        const option = this.product.options.find((option: any) => option.id === this.item.option)

        return this.option = option
    }
}
