







import { Vue, Component, Emit, Prop } from "vue-property-decorator";

@Component
export default class CartButtton extends Vue {

    @Prop() private cartCount: number

    @Emit()
    openCart(e: any) {
        e.preventDefault()
        return true
    }
}
