


























import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import CartItem from './CartItem.vue'
@Component({
    components: {
        CartItem
    }
})
export default class Cart extends Vue {

    @Prop() private cartItems: any[]
    @Prop() private cartTotal: any

    created() {
        console.log(this.cartItems);
    }

    @Emit()
    closeCart() {
        return true
    }

    @Emit()
    decreaseItem(item: any) {
        return item
    }

    @Emit()
    increaseItem(item: any) {
        return item
    }
}
