import Vue from "vue";
import CartPanel from './vue/components/Cart.vue'
import CartButton from './vue/components/CartButton.vue'
import CheckoutItem from './vue/components/CheckoutItem.vue'
import CheckoutTotal from './vue/components/CheckoutTotal.vue'
import VueSnotify from "vue-snotify";

var componentList = {
 CartPanel,
 CartButton,
 CheckoutItem,
 CheckoutTotal
}
window.BigCartel = {
    Cart,
    Product,
    Format
}

Vue.use(VueSnotify, {
    toast: {
      showProgressBar: true,
      icon: false,
      position: "rightBottom",
      newOnTop: false,
    },
});

var body = document.getElementsByTagName('body')[0]
var domLoad = function() {

    var app = new Vue({
        el: "#app",
        components: componentList,
        methods: {
            updateCart: function(cart) {
                app.cartItems = cart.items
                app.cartItemCount = cart.item_count
                app.cartTotal = cart.total
            },
            toggleCart: function (isVisible){
                app.showCart = isVisible
                if(app.showCart) {
                  return body.classList.add('sus-cart-visible')
                }
                return body.classList.remove('sus-cart-visible')
            },
            updateItem: function (item) {
                Cart.updateItem(item.option, item.quantity, this.updateCart)
            },
            checkoutSubmit: function (e) {
                let childOver = false
                this.$children.forEach((child) => {
                    if(child.overStock){
                        return childOver = true
                    }
                })
                if(childOver) {
                    e.preventDefault()
                    return this.$snotify.error('Please adjust quantities before proceeding.')
                }
            }
        },
        created: function(){
            Cart.refresh(function(cart) {
                app.cartItems = cart.items
                app.cartItemCount = cart.item_count
                app.cartTotal = cart.total
            })
        },
        data: {
            cartItems: [],
            cartItemCount: 0,
            cartTotal: 0,
            showCart: false,
            showCheckoutBtn: false,
            showOffscreenNav: false
        }
      });

    // elements

    var video = document.getElementById('banner-video')
    var videoSrc = {
        mobile: 'https://grumble.dev/sus/video-story.mp4',
        desktop: 'https://grumble.dev/sus/video.mp4'
    }
    var productForm = document.getElementById('sus-product-form')
    var categorySelect = document.getElementById('sus-category-select')
    var susFlickity = document.getElementById('sus-flickity')
    if(video) {
        video.setAttribute('src', window.innerWidth > 800 ? videoSrc.desktop : videoSrc.mobile)
    }
    // Handlers

    if(categorySelect) {
        categorySelect.addEventListener('change', function (e) {
            window.location.href = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '' )+ e.target.value
        })
    }

    if(susFlickity) {
        var flkty = new Flickity(susFlickity, {
            // options
            cellAlign: 'left',
            contain: true,
            imagesLoaded: true
          });
    }

    var cartBtns = document.querySelectorAll(
        "[data-toggle-cart]"
      );
  
    cartBtns.forEach((btn) => {
        btn.addEventListener("click", app.toggleCart);
    });

    // Events

    if(video) {
        video.onplay = function() {
            video.classList.add('sus-banner__video--playing')
        }
    
    }

    if(productForm) {
        var submitBtn = productForm.getElementsByTagName('button')[0]
         productForm.addEventListener('submit', function(e) {
             e.preventDefault()

             submitBtn.disabled = true
             submitBtn.classList.add('sus-rgb-button--loading')

             Cart.updateFromForm('sus-product-form', function(cart) {
                app.$snotify.success('Added item to cart!')
                app.showCheckoutBtn = true
                app.updateCart(cart)
                submitBtn.disabled = false
                submitBtn.classList.remove('sus-rgb-button--loading')
             })
         })
    }


    
}
domLoad()