
























import { Vue, Component, Emit, Prop, Watch } from "vue-property-decorator";

@Component
export default class CheckoutItem extends Vue {

    @Prop() private items: any
    @Prop() private itemId: any
    @Prop() private inventory: any
    private product: any = null
    private option: any = null

    async created() {
        if(this.item){
            this.getProduct()
        }
    }

    @Watch('items')
    getProduct() {
        if(this.item && this.item.quantity > 0) {
            (window as any).BigCartel.Product.find(this.item.product, this.setProduct)
        }
    }

    setProduct(product: any) {
        this.product = product
    }    

    @Emit()
    decreaseItem() {
        return { option: this.item.id, quantity: this.item.quantity - 1}
    }

    @Emit()
    increaseItem() {
        return { option: this.item.id, quantity: this.item.quantity + 1}
    }

    get item () {
        const item = this.items.find((i: any) => i.id === this.itemId)

        if(!item){
            return false 
        }


        return item
    }

    @Watch('product')
    getOption() {
        if(!this.product){
            return false
        }

        const option = this.product.options.find((option: any) => option.id === this.item.option)

        return this.option = option
    }

    get overStock() {
        if(!this.item){
            return true
        }

        return this.item.quantity > this.inventory
    }
}
