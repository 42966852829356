








import { Vue, Component, Emit, Prop } from "vue-property-decorator";

@Component
export default class CheckoutTotal extends Vue {
    @Prop() private subtotal: any
    
    @Emit()
    checkoutSubmit(e: any){
        return e
    }
}
